import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchAcList, addAc, delAc } from "@/api/member";
import { transAcType } from "@/utils/util";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
export default {
  name: "ac",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: ""
        },
        time: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      acOption: _global.acOption,
      chargeType: _global.chargeType
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      console.log(data);
      fetchAcList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.$message({
          type: "warning",
          message: err.data["message"]
        });

        _this.tableLoading = false;
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log("页数");
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleDel: function handleDel(row) {
      var _this2 = this;

      console.log("del");
      var name = "";

      if (row.manager) {
        name = row.manager.name;
      }

      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664 ".concat(name, " \u53C2\u4E0E\u7684\u6D3B\u52A8?");
      var title = "确认删除";
      this.$confirm(content, title, {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "error"
      }).then(function () {
        delAc(row.id).then(function (res) {
          console.log(res);

          _this2.$message({
            type: "success",
            message: "删除成功"
          });

          var index = _this2.dataList.indexOf(row);

          _this2.dataList.splice(index, 1);
        }).catch(function (err) {
          _this2.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    transAcType: transAcType
  }
};